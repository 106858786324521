/*
1. back to top script
2. progressbar
3. tab js
4. For Chart
5. For Expired Timer
6. horizontal timeline
7. video-popup
8. owlcarusel initialization
9. carousel resize js
10. animation wow
11. app preloader js
12. fat-nav js
*/

jQuery(function() {

    /*===================================================================================*/
    /*  animation wow
    /*===================================================================================*/
    if (jQuery('.wow').length > 0) {
        jQuery(function(){
            var wow = new WOW({
                boxClass: 'wow',
                animateClass: 'animated',
                offset: 0,
                mobile: false,
                live: true,
                scrollContainer: null,
            });
            wow.init();
        });
    }
});
